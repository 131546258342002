import React from 'react';
import playStoreImage from '../../assets/img/play-store1.svg';
import playStoreImage2 from '../../assets/img/play_store2.svg';
import bannerman from '../../assets/img/banner_man.png';
import bannerman2 from '../../assets/img/bnr1_man2.png';
import bannerImage from '../../assets/img/banner_bg.jpg';
import { Button } from 'reactstrap';
import { Link } from 'react-scroll';

export default function Banner() {


  return (
    <>
      <section className='banner_outer' style={{ background: `url(${bannerImage})` }}>
        <div className="container ">
          <div className="row banner d-flex justify-content-center align-items-end">
            <div className="col-6 d-flex justify-content-center">
              <div className='banner_text text-center'>
                {/* <h1 class="glitch" data-text="TOPTIPPERS">TOPTIPPERS</h1> */}
                <h1 class="glitch" data-text="TOPTIPPERS">TOPTIPPERS</h1>
                <h2>Changing the Game</h2>
                <p><span className='bold_text'>Join the next generation of footy tipping on <i>TOPTIPPERS.</i><br /></span>
                  Invite your family and friends to download and<br /> 
                  play for <i>FREE.</i><br /><br />
                  <b>Find us on the App Store or Google Play.</b> </p><br /><br />
                <div className="row">
                  <div className="col-md-6">
                    <a rel="noreferrer" href="https://apps.apple.com/au/app/toptippers/id1549367466" target="_blank">
                      <img src={playStoreImage} alt="playstore" className="img-fluid" />  
                    </a>
                  </div>
                  <div className="col-md-6">
                    <a rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.toptippersApp" target="_blank" >
                      <img src={playStoreImage2} alt="playstore" className="img-fluid" />
                    </a>
                  </div>
                </div>
              </div>

            </div>
            <div className="col-6">
              <div className='banner_img'>
                <img src={bannerman} alt="playstore" className="img-fluid" />
                <img className='mobile-only-div' src={bannerman2} alt="playstore" />
              </div>
            </div>
          </div>
          <Link
                activeClass="active"
                to="welcomeSection"
                spy={true}
                smooth={true}
                offset={-80} // Adjust this value based on your design
                duration={200} // Duration of the scrolling animation
                className="scroll-down-button"
            >
          <Button className="scroll-down-button">
            {/* <img src={Doubledown} alt="Doubledown"/> */}
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="36" viewBox="0 0 40 36" fill="none">
              <g clip-path="url(#clip0_130_12)">
                <path d="M2.55156 11.618L19.7773 30.4547L37.4492 11.1719L40 13.5055L19.775 35.575L0 13.9516L2.55156 11.618Z" fill="black" />
                <path d="M2.55156 -0.100781L19.7773 18.7359L37.4492 -0.546875L40 1.78672L19.775 23.8562L0 2.23281L2.55156 -0.100781Z" fill="black" />
              </g>
              <defs>
                <clipPath id="clip0_130_12">
                  <rect width="40" height="35.5469" fill="white" /> 
                </clipPath>
              </defs>
            </svg>
          </Button>
          </Link>
        </div>
      </section>
    </>
  );
}
