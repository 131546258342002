import React from 'react'
import { Button } from 'reactstrap';
import bnr2_man from '../../assets/img/bnr2_man.png';
import playStoreImage from '../../assets/img/play-store1.svg';
import playStoreImage2 from '../../assets/img/play_store2.svg';

export default function GetStarted() {
    return (
        <>
            <section className='get_started'>
                <div className="container ">
                    <div className="row get_started_main d-flex justify-content-center align-items-center">
                        <div className="col-6 d-flex justify-content-between">
                            <div className='get_started_img'>
                                <img src={bnr2_man} alt="HandSmartphone" />
                            </div>
                        </div>
                        <div className="col-6 d-flex justify-content-center">
                            <div className='get_started_text'>
                                <h3>
                                    GET STARTED NOW <span>FOR FREE!</span>
                                </h3>
                                <Button color="primary dffg" >
                                    Download Now
                                </Button>
                                <div className="row play_store">
                                    <div className="col-md-6">
                                        <a rel="noreferrer" href="https://apps.apple.com/au/app/toptippers/id1549367466" target="_blank">
                                            <img src={playStoreImage} alt="playstore" className="img-fluid" />
                                        </a>
                                    </div>
                                    <div className="col-md-6">
                                        <a rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.toptippersApp" target="_blank" >
                                            <img src={playStoreImage2} alt="playstore" className="img-fluid" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
