import './App.css';
import './sass/styles.scss';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/Home';
import Terms from './components/InnerPages/Terms';  
import PrivacyPolicy from './components/InnerPages/PrivacyPolicy';
import Contact from './components/InnerPages/Contact';


function App() {
  return (
    <>
      <Router>

        <Header />
        <Routes>
          <Route exact path='/' element={< Home />}></Route>
          <Route exact path='/terms' element={< Terms />}></Route>
          <Route exact path='/privacy-policy' element={< PrivacyPolicy />}></Route>          
          <Route exact path='/contact' element={< Contact />}></Route>

        </Routes>
        <Footer />
      </Router>

    </>
  );
}

export default App;
