import React, { useState } from 'react'
import { Button, Form, FormGroup, Label, Input, Container, Row, Col } from 'reactstrap';
import DownloadNow from './DownloadNow';
import './inner.scss'

export default function Contact() {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        subject: '',
        comments: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Form data:', formData);
    };

    return (
        <>
            <div className='inner_pages'>
                <Container className="mt-5">
                    <Row>
                        <Col md="10" className="mx-auto mb-5">
                            <h2 className='text-center'>Contact Us</h2>
                            <p className='mb-5'>If you need to speak to the team at TOPTIPPERS for whatever reason, please feel free to send an email to <a href="mailto:admin@toptippers.com">admin@toptippers.com</a> or fill out this form below</p>
                            <Form onSubmit={handleSubmit}>
                            <Row>
                              <Col md={6}>
                                <FormGroup>
                                    <Label for="firstName">First Name *</Label>
                                    <Input
                                        type="text"
                                        name="firstName"
                                        id="firstName"
                                        value={formData.firstName}
                                        onChange={handleChange}
                                    />
                                    <span>Enter your first name here</span>
                                </FormGroup>
                                </Col> 
                                <Col md={6}>
                                <FormGroup>
                                    <Label for="lastName">Last Name *</Label>
                                    <Input
                                        type="text"
                                        name="lastName"
                                        id="lastName"
                                        value={formData.lastName}
                                        onChange={handleChange}
                                    />
                                    <span>Enter your last name here</span>
                                </FormGroup>
                                </Col>
                                </Row>
                                <Row>
                              <Col md={6}>
                                <FormGroup>
                                    <Label for="email">Email *</Label>
                                    <Input
                                        type="email"
                                        name="email"
                                        id="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                    />
                                    <span>Example: user@website.com</span>
                                </FormGroup>
                                </Col>
                                <Col md={6}>
                                <FormGroup>
                                    <Label for="subject">Subject *</Label>
                                    <Input
                                        type="text"
                                        name="subject"
                                        id="subject"
                                        value={formData.subject}
                                        onChange={handleChange}
                                    />
                                    <span>How can we help you?</span>
                                </FormGroup>
                                </Col>
                                </Row>
                                <FormGroup>
                                    <Label for="comments">Comments / Questions *</Label>
                                    <Input
                                        type="textarea"
                                        name="comments"  
                                        id="comments"
                                        value={formData.comments}
                                        onChange={handleChange}
                                    />
                                    <span>Enter your comment / question here</span>
                                </FormGroup>
                                <Button color="primary" type="submit">
                                    Send Message
                                </Button>
                            </Form>
                            <DownloadNow/>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}
