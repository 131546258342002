import React from 'react'
import playStoreImage from '../../assets/img/play-store1.svg';
import playStoreImage2 from '../../assets/img/play_store2.svg';

export default function DownloadNow() {
    return (
        <>
            <div className='get_started_text innerpage text-center my-5'>
                <h3>
                    Download NOW <span>FOR FREE!</span>  
                </h3>
                <div className="row play_store">
                    <div className="col-6">
                        <a href="https://apps.apple.com/au/app/toptippers/id1549367466" rel="noreferrer" target="_blank">
                            <img src={playStoreImage} alt="playstore" className="img-fluid" />
                        </a>
                    </div>
                    <div className="col-6">
                        <a href="https://play.google.com/store/apps/details?id=com.toptippersApp" rel="noreferrer" target="_blank" >
                            <img src={playStoreImage2} alt="playstore" className="img-fluid" />
                        </a>
                    </div>
                </div>
            </div>

        </>
    )
}
