import React from 'react'
import Banner from './Home/Banner'
import Welcome from './Home/Welcome'
import Communicate from './Home/Communicate'
import Bonus from './Home/Bonus'
import GetStarted from './Home/GetStarted'
import { Element } from 'react-scroll';

export default function Home() {
  return (
    <>
    <Banner /> 
    <Element name="welcomeSection">
       <Welcome/>
    </Element>  
    <Element name="communicateSection">
       <Communicate/>
    </Element>      
    <Bonus/>
    <GetStarted/>   
    </>
  )
}
