import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Ball from '../../assets/img/ball.png';
import { Button } from 'reactstrap';

export default function Bonus() {
    const sliderItems = [
        {
            title: '<span>BONUS</span> system',
            content: 'Along with our innovative game values, TOPTIPPERS offers a series of bonuses that livens up your game. Each competition is fully customisable, allowing you to tailor your competition. Don’t settle for just joker rounds, change your game and experience the difference today.',
        },
        {
            title: '<span>Heater</span> BONUS',
            content: 'A bonus that really fires up your score. The Heater Bonus is a four-game sequence bonus. Once a player correctly tips four games in a row, every game tipped correctly afterwards is automatically doubled.',
        },
        {
            title: '<span>Smug</span> Tips',
            content: 'Are you frustrated with that player who always tips the favourites? Take a chance and get rewarded with the Smug Tip. If you’re the only player to tip a team that wins, your score will double automatically. ',
        },
        {
            title: '<span>Round</span> BONUSES',
            content: 'The stock standard staple for any good tipping comp. Have your choice of a Double OR Triple round bonus to accelerate your score.',
        },
        {
            title: '<span>Finals</span> Frenzy',
            content: 'It isn’t over until the grand final whistle is blown. Score double points during the finals and charge home to take the lead. Who doesn’t love to finish the year off with a little craziness?',
        },
     
    ];
    const settings = {
        dots: true,
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        //vertical: true,         
        verticalSwiping: true,        
        adaptiveHeight:true,
        variableHeight:true,
        prevArrow: false, 
        nextArrow: false ,
        centerMode: false,
        centerPadding: "0",
    };  

    return (
        <section className='welcome bonus'>
            <div className="container ">
                <div className="row welcome_main d-flex justify-content-center align-items-center">
                    <div className="col-md-6">
                                                    
                            <Slider {...settings}>
                                {sliderItems.map((item, index) => (
                                    <div key={index}  className='welcome_text'>
                                        <h3 dangerouslySetInnerHTML={{ __html: item.title }}></h3>
                                        <p>{item.content}</p>
                                    </div>
                                ))}
                            </Slider>
                        
                    </div>
                    <div className="col-md-6 d-flex justify-content-center">
                        <div className='welcome_img'>
                            <img src={Ball} alt="HandSmartphone" />
                        </div>
                    </div>
                </div>
                <Button className="scroll-down-button">
                    {/* Your SVG code here */}
                </Button>
            </div>
        </section>
    );
}
