import React from 'react'
import HandSmartphone from '../../assets/img/HandSmartphone.png';
import HandSmartphone2 from '../../assets/img/HandSmartphone2.png';
import { Button } from 'reactstrap';
import { Link } from 'react-scroll';

export default function Welcome() {
    return (
        <>
            <section className='welcome'>
                <div className="container ">
                    <div className="row welcome_main d-flex justify-content-center align-items-center">
                        <div className="col-6 d-flex justify-content-center d-none d-lg-block">
                            <div className='welcome_img'>
                                <img src={HandSmartphone} alt="HandSmartphone" />
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-6 d-flex justify-content-center">
                            <div className='welcome_text'>
                                <img className="mobile-only-div" src={HandSmartphone2} alt="HandSmartphone" />
                                <h3>
                                    Welcome to <span>TOPTIPPERS</span>
                                </h3>
                                <p>
                                    Not every game is equal, so why should your tipping comp treat it that way?<br />
                                    <br />

                                    <i>TOPTIPPERS</i> has revolutionised the standard tipping competition by acknowledging the difference between the underdog and the favourite. Utilising current market trends, <i>TOPTIPPERS</i> values each team specifically for each game of the round. This updated scoring system creates a more exciting player experience that pushes each player’s comfort zone.
                                    <br />
                                    <br />
                                    So, if you’re tired of that one player who always tips the favourite,
                                    <i>TOPTIPPERS</i> is the game for you.

                                </p>
                            </div>
                        </div>
                    </div>
                    <Link
                        activeClass="active"
                        to="communicateSection"  
                        spy={true}
                        smooth={true}
                        offset={80} // Adjust this value based on your design
                        duration={200} // Duration of the scrolling animation
                        className="scroll-down-button"
                    >
                        <Button className="scroll-down-button">
                            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="36" viewBox="0 0 40 36" fill="none">
                                <g clip-path="url(#clip0_130_15)">
                                    <path d="M2.55162 11.618L19.7774 30.4547L37.4493 11.1719L40.0001 13.5055L19.7751 35.575L6.10352e-05 13.9516L2.55162 11.618Z" fill="#E08300" />
                                    <path d="M2.55162 -0.100781L19.7774 18.7359L37.4493 -0.546875L40.0001 1.78672L19.7751 23.8562L6.10352e-05 2.23281L2.55162 -0.100781Z" fill="#E08300" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_130_15">
                                        <rect width="40" height="35.5469" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </Button>
                    </Link>
                </div>
            </section>
        </>
    )
}
