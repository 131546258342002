import React from 'react'
import comp1 from '../../assets/img/comp1.png';  
import comp2 from '../../assets/img/comp2.png';
import comp3 from '../../assets/img/comp3.png';


export default function Communicate() {
    return (
        <>
            <section className='comunicate'>
                <div className="container">
                    <div className="row comunicate_one d-flex justify-content-between align-items-center">
                        <div className="col-6 d-flex justify-content-center">
                            <div className='comunicate_text'>
                                <h3>
                                    compete with friends
                                </h3>
                                <p>
                                    Whether it’s the office comp, mates from school or your local footy team, TOPTIPPERS provides the perfect platform to host your tipping competition.
                                </p>
                            </div>
                        </div>
                        <div className="col-6 d-flex justify-content-center">
                            <div className='comunicate_img'>
                                <img src={comp1} alt="HandSmartphone" />
                            </div>
                        </div>


                    </div>
                    <div className="row comunicate_one d-flex justify-content-between align-items-center">
                        <div className="col-6 d-flex justify-content-center">
                            <div className='comunicate_img'>
                                <img src={comp2} alt="HandSmartphone" />
                            </div>
                        </div>

                        <div className="col-6 d-flex justify-content-center">
                            <div className='comunicate_text'>
                                <h3>
                                    communicate  
                                </h3>
                                <p>
                                    Bragging rights have taken on a new twist this season. Rile up your mates with our group chat feature, BANTER.
                                </p>
                            </div>
                        </div>


                    </div>
                    <div className="row comunicate_one d-flex justify-content-between align-items-center">
                        <div className="col-6 d-flex justify-content-center">
                            <div className='comunicate_text'>
                                <h3>
                                    Stay up to date
                                </h3>
                                <p>
                                    Keep your finger on the pulse with our SPORTS NEWS section.
                                    Get the edge by reading the latest sports news on TOPTIPPERS.

                                </p>
                            </div>
                        </div>
                        <div className="col-6 d-flex justify-content-center">
                            <div className='comunicate_img'>
                                <img src={comp3} alt="HandSmartphone" />
                            </div>
                        </div>


                    </div>
                </div>
            </section>
        </>
    )
}
