import React from 'react'
import { Container, Row, Col } from 'reactstrap';
import {Link } from 'react-router-dom';
import FB from '../../assets/img/FB.svg';
import Insta from '../../assets/img/Insta.svg';
import "./Footer.scss"  
import Logo from "../../assets/img/logo.png"
import TopSport from "../../assets/img/TopSport_logo.svg"


export default function Footer() {  
  return (
    <>
    <footer className="text-white">
      <Container>
        <Row className='footer_top d-flex justify-content-center align-items-center'>
          <Col xs={12} sm={12} md={4} text-center >
            <div className="">
              <div className='log_footer'>
                <img src={Logo} alt='logo' className='logo'/>
                <h4><span>Changing the Game</span></h4>
              </div>
            </div>
            <ul className="links_foot">
                <li>
                  <Link to="/terms">Terms</Link>
                </li>
                <li>
                  <a href="/privacy-policy">Privacy Policy</a>
                </li>
                <li>
                  <a href="/contact">Contact</a>  
                </li>
              </ul> 
          </Col>
          <Col xs={12} sm={12} md={4}>
            <div className="mb-4">
              <ul className="socails links_foot">
              <li>
                <a href="https://www.facebook.com/toptippersgame/"><img src={FB} alt='facebook' /></a>
              </li>
              <li>
                <a href="https://www.instagram.com/toptippers_game/"><img src={Insta} alt='Insta' /></a>
              </li>
            </ul>
            </div>
          </Col>
          <Col xs={12} sm={12} md={4} className='text-end'>
            <div className="power_by ">
              <h5>Powered by</h5>
              <img src={TopSport} alt='TopSport' />
            </div>
          </Col>
        </Row>        
      </Container>
      <Container fluid className='footer_bottom'>
        <Row >
          <Col xs={12}>
            <p className="mt-4 text-center">
              CHANCES ARE YOU ARE ABOUT TO LOSE. <br />
              For free and confidential support, call <a href="tel:1800 858 858" rel="noopener noreferrer">1800 858 858</a> or visit{' '}
              <a href="https://www.gamblinghelponline.org.au/" target="_blank" rel="noopener noreferrer">
                gamblinghelponline.org.au
              </a>
              .
            </p>
          </Col>
        </Row>
        </Container>
    </footer>
    
    </>
  )
}
