import React, { useEffect, useState } from 'react';
import { Navbar, NavbarBrand, Nav, NavItem, NavLink } from 'reactstrap';
import "./Header.scss"
import Logo from "../../assets/img/logo.png"

export default function Header() {
  const [isScrolled, setIsScrolled] = useState(false);
  const isHomePage = window.location.pathname === '/';

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 250) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return ( 
    <header>
      <Navbar className={`header_top light expand  ${isHomePage ? '' : 'other-header'}`} container={true}>
        <NavbarBrand href="/"><div className='head_footer'>
          <img src={Logo} alt='logo' className='logo'/>
          <h4><span>Changing the Game</span></h4>
        </div></NavbarBrand>
        <Nav className="ml-auto" navbar>
          <NavItem>
            <NavLink href="https://www.facebook.com/toptippersgame/"><svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
              <path d="M20 0C8.955 0 0 8.955 0 20C0 30.0267 7.38667 38.3067 17.01 39.7533V25.3H12.0617V20.0433H17.01V16.545C17.01 10.7533 19.8317 8.21167 24.645 8.21167C26.95 8.21167 28.17 8.38333 28.7467 8.46V13.0483H25.4633C23.42 13.0483 22.7067 14.9867 22.7067 17.17V20.0433H28.695L27.8833 25.3H22.7067V39.795C32.4683 38.4717 40 30.125 40 20C40 8.955 31.045 0 20 0Z" fill="black" />
            </svg></NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="https://www.instagram.com/toptippers_game/"><svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
              <path d="M20 0C8.97231 0 0 8.97231 0 20C0 31.0277 8.97231 40 20 40C31.0277 40 40 31.0277 40 20C40 8.97231 31.0277 0 20 0ZM14.8708 6.15385H25.1262C29.9339 6.15385 33.8462 10.0646 33.8462 14.8708V25.1262C33.8462 29.9339 29.9354 33.8462 25.1292 33.8462H14.8738C10.0661 33.8462 6.15385 29.9354 6.15385 25.1292V14.8738C6.15385 10.0661 10.0646 6.15385 14.8708 6.15385ZM14.8708 9.23077C11.7616 9.23077 9.23077 11.763 9.23077 14.8738V25.1292C9.23077 28.2384 11.763 30.7692 14.8738 30.7692H25.1292C28.2384 30.7692 30.7692 28.237 30.7692 25.1262V14.8708C30.7692 11.7616 28.237 9.23077 25.1262 9.23077H14.8708ZM27.1815 11.7939C27.7476 11.7939 28.2061 12.2524 28.2061 12.8185C28.2061 13.3847 27.7476 13.8462 27.1815 13.8462C26.6153 13.8462 26.1538 13.3847 26.1538 12.8185C26.1538 12.2524 26.6153 11.7939 27.1815 11.7939ZM20 12.3077C24.2415 12.3077 27.6923 15.7585 27.6923 20C27.6923 24.2415 24.2415 27.6923 20 27.6923C15.7585 27.6923 12.3077 24.2415 12.3077 20C12.3077 15.7585 15.7585 12.3077 20 12.3077ZM20 15.3846C18.7759 15.3846 17.602 15.8709 16.7364 16.7364C15.8709 17.602 15.3846 18.7759 15.3846 20C15.3846 21.2241 15.8709 22.398 16.7364 23.2636C17.602 24.1291 18.7759 24.6154 20 24.6154C21.2241 24.6154 22.398 24.1291 23.2636 23.2636C24.1291 22.398 24.6154 21.2241 24.6154 20C24.6154 18.7759 24.1291 17.602 23.2636 16.7364C22.398 15.8709 21.2241 15.3846 20 15.3846Z" fill="black" />
            </svg></NavLink>
          </NavItem>
          {/* Add more social links as needed */}
        </Nav>
      </Navbar>
      <Navbar className={`header ${isScrolled ? 'sticky' : ''}`} light expand="md" container={true}>
        <NavbarBrand href="/"><div className='head_footer'>
          <img src={Logo} alt='logo' className='logo'/>
          <h4><span>Changing the Game</span></h4>
        </div></NavbarBrand>
        <Nav className="ml-auto" navbar>          
          <NavItem>
            <NavLink href="https://www.facebook.com/toptippersgame/"><svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
              <path d="M20 0C8.955 0 0 8.955 0 20C0 30.0267 7.38667 38.3067 17.01 39.7533V25.3H12.0617V20.0433H17.01V16.545C17.01 10.7533 19.8317 8.21167 24.645 8.21167C26.95 8.21167 28.17 8.38333 28.7467 8.46V13.0483H25.4633C23.42 13.0483 22.7067 14.9867 22.7067 17.17V20.0433H28.695L27.8833 25.3H22.7067V39.795C32.4683 38.4717 40 30.125 40 20C40 8.955 31.045 0 20 0Z" fill="black" />
            </svg></NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="https://www.instagram.com/toptippers_game/"><svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
              <path d="M20 0C8.97231 0 0 8.97231 0 20C0 31.0277 8.97231 40 20 40C31.0277 40 40 31.0277 40 20C40 8.97231 31.0277 0 20 0ZM14.8708 6.15385H25.1262C29.9339 6.15385 33.8462 10.0646 33.8462 14.8708V25.1262C33.8462 29.9339 29.9354 33.8462 25.1292 33.8462H14.8738C10.0661 33.8462 6.15385 29.9354 6.15385 25.1292V14.8738C6.15385 10.0661 10.0646 6.15385 14.8708 6.15385ZM14.8708 9.23077C11.7616 9.23077 9.23077 11.763 9.23077 14.8738V25.1292C9.23077 28.2384 11.763 30.7692 14.8738 30.7692H25.1292C28.2384 30.7692 30.7692 28.237 30.7692 25.1262V14.8708C30.7692 11.7616 28.237 9.23077 25.1262 9.23077H14.8708ZM27.1815 11.7939C27.7476 11.7939 28.2061 12.2524 28.2061 12.8185C28.2061 13.3847 27.7476 13.8462 27.1815 13.8462C26.6153 13.8462 26.1538 13.3847 26.1538 12.8185C26.1538 12.2524 26.6153 11.7939 27.1815 11.7939ZM20 12.3077C24.2415 12.3077 27.6923 15.7585 27.6923 20C27.6923 24.2415 24.2415 27.6923 20 27.6923C15.7585 27.6923 12.3077 24.2415 12.3077 20C12.3077 15.7585 15.7585 12.3077 20 12.3077ZM20 15.3846C18.7759 15.3846 17.602 15.8709 16.7364 16.7364C15.8709 17.602 15.3846 18.7759 15.3846 20C15.3846 21.2241 15.8709 22.398 16.7364 23.2636C17.602 24.1291 18.7759 24.6154 20 24.6154C21.2241 24.6154 22.398 24.1291 23.2636 23.2636C24.1291 22.398 24.6154 21.2241 24.6154 20C24.6154 18.7759 24.1291 17.602 23.2636 16.7364C22.398 15.8709 21.2241 15.3846 20 15.3846Z" fill="black" />
            </svg></NavLink>
          </NavItem>
          {/* Add more social links as needed */}
        </Nav>
      </Navbar>
    </header>
  )
}
